import $ from "jquery";
import "iframe-resizer";

var $iframes = $();

var _total = 0;

export function load() {
    $iframes = $('body').find('iframe[src*="cloudworx"]');
    if ($iframes.length) start();
};

function start() {
    //fix for cross origin in subdomains
    if (window.location.origin.indexOf('cloudworx.agency') !== -1) {
        document.domain = 'cloudworx.agency';
    }
    loadIframes();
}

function loadIframes() {

    $iframes.each(function() {

        var $this = $(this);
        var src = $this.prop('src');
        var isloaded = false;

        iFrameResize({}, '[src="'+src+'"]');

        $(window).on("message", function(e) {

            if (typeof e.originalEvent.data === 'string' && (e.originalEvent.data.indexOf('[iFrameSizer]iFrameResizer') !== -1 && e.originalEvent.data.indexOf(':init') !== -1)  
                && src.replace(/^(.*?\/\/[^/]+).*$/,'$1') == e.originalEvent.origin.replace(/^(.*?\/\/[^/]+).*$/,'$1') 
                && !isloaded) {
                isloaded = true;
                loadValues($this);
            }

        });

        $this.on('load', function() {

            if (!isloaded) {
                isloaded = true;
                loadValues($this);
            }

        });

    });
}

function loadValues($iframe) {

    var reg = /{{(.*?)}}/g;
    var fieldname;
    var fieldvalue;
    var tagwrapper;
    var matches = [];
    var iframeContent = $iframe.contents();
    while ((fieldname = reg.exec(document.body.innerHTML)) !== null) {
        matches.push(fieldname[1]);
    }
    console.log($iframe[0], $iframe[0].contentWindow.document, $iframe[0].contentDocument);
    matches.forEach(function(match) {
        fieldvalue = iframeContent.find('[name="'+match+'"]').val();
        tagwrapper = $(':contains("{{'+match+'}}")').last();
        if (tagwrapper.length && fieldvalue)
            tagwrapper.html(tagwrapper.html().replace('{{'+match+'}}', fieldvalue));
        else if (tagwrapper.length)
            tagwrapper.html(tagwrapper.html().replace('{{'+match+'}}', ''));
    });
}

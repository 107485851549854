import $ from "jquery";
window.$ = window.jQuery = $;
import "waypoints/lib/noframework.waypoints.js";
import "slick-carousel";
import * as mobilefix from "./mobilefix";
import * as wmbox from "./wmbox";
import * as popup from "./popup";
import * as loader from "./loader";
import * as iframeLoader from "./iframe-loader";
import * as creator from "./creator";

$.fn.scrollTo = function(offsetTop) {
    if ($(this).length) {
        offsetTop = typeof offsetTop !== 'undefined' ? offsetTop : 0;
        var top = $(this).eq(0).offset().top-offsetTop;
        $('html, body').animate({
            scrollTop : top
        },500);
    }
};

$(document).ready(function() {
	
	loader.load();
	iframeLoader.load();

	mobilefix.init('.mobilefix');
	wmbox.init();
	creator.init();

	//don't show cta popup on funnels
	if (!location.pathname.match(/sales/)) {
		popup.init('popup');
	}

	$('body').on('click', '[popup]', function(e) {
		e.preventDefault();
		var url = $(this).attr('popup');
		if (!$('#'+$.escapeSelector(url)).length) {
			var popupsWrapper = $('<div id="popups-wrapper">').appendTo('body');
			popupsWrapper.load('/'+url+'?embed=1 .popup',function() {
				loader.load(popupsWrapper);
				popupsWrapper.children().unwrap();
				popup.init(url, false);
			});
		}
	});

	$(window).on('message', function(e) { 
		if (e.originalEvent.origin.indexOf('cloudworx') && typeof e.originalEvent.data === 'object' && e.originalEvent.data !== null) {
			if (typeof e.originalEvent.data.redirectLocation !== 'undefined') {
				window.location.href = e.originalEvent.data.redirectLocation;
			}
		}
	}); 

	$('body').on('click', '.button[href]', function() {
		var label = $(this).prop('href');
		if (label.indexOf('#') !== -1 && label.indexOf('cloudworx') !== -1) label = label.substring(label.indexOf('#')+1);
	});

	$('[data-index]').on('mouseenter mouseleave', function() {
		$('[data-index="'+$(this).attr('data-index')+'"]').toggleClass('hover');
	}).on('click', function(e) {
		if ($(this).attr('data-index') != '/') {
			e.preventDefault();
			$('#'+$.escapeSelector($(this).attr('data-index'))).scrollTo();
		}
	});

	$('.nav-wrapper, .nav-activearea').on('mouseenter', function() {
		$(this).parent().find('[data-index]').addClass('show');
	}).on('mouseleave', function() {
		$(this).parent().find('[data-index]').removeClass('show');
	});

	$('.nav-button').on('click', function(e) {
		e.preventDefault();
		$('.nav').toggleClass('nav--opened');
	});
	$('.fullpage').on('click', ':not(.nav-button, .grid-info)', function(e) {
		$('.nav').removeClass('nav--opened');
	});
	$('.simplenav-button').on('click', function(e) {
		e.preventDefault();
		$('.simplenav--object').toggleClass('simplenav--opened');
		$('.grid-info--left .logo').toggleClass('simplenav--opened');
	});
	$('.fullpage').on('click', ':not(.nav-button, .grid-info)', function(e) {
		if ($('.simplenav--object').length) {
			$('.simplenav--object').removeClass('simplenav--opened');
			$('.grid-info--left .logo').removeClass('simplenav--opened');
		}
	});

	$('.nav-el').on('click', function(e) {
		$('.nav').removeClass('nav--opened');
	});

	$('.module-textlist').each(function() {
		$(this).find('.module-textlist-body').eq(0).slideDown(function() {
			Waypoint.refreshAll();
		})
		
	});

	$('.module-textlist-head').on('click', function() {
		if (!$(this).hasClass('active')) {
			var $activeBody = $(this).parents('.module-textlist').find('.module-textlist-body.active');
			if ($activeBody.length && $activeBody.index() < $(this).index()) 
				$(this).scrollTo(100+$activeBody.height());
			else
				$(this).scrollTo(100);
			$(this).parents('.module-textlist').find('.module-textlist-head').removeClass('active');
			$(this).parents('.module-textlist').find('.module-textlist-body').removeClass('active').slideUp(500);
			$(this).addClass('active').next().addClass('active').slideDown(500, function() {
					Waypoint.refreshAll();
				});
		}
	});

	$('.button--canfix').each(function() {
		new Waypoint({
			element: this,
			handler: function(direction) {
				var $this = $(this.element);
				if (direction == 'up') {
					$this.parent().css('height', '');
					$this.removeClass('button--isfixed');
				} else {
					$this.parent().css('height', $this.parent().height());
					$this.addClass('button--isfixed');
				}
			}
		});
	});

	$('.fullpage.fullpage--showinnav-yes').each(function() {
		new Waypoint({
			element: this,
			handler: function(direction) {
				$('.nav-el[data-index]').removeClass('active');
				if (direction == 'up') {
					var id = $(this.element).prev()[0].id;
					if (parseInt($('.nav-line.inactive').attr('data-grow')) >= 0) {
						$('.nav-line.active').attr('data-grow', parseInt($('.nav-line.active').attr('data-grow'))-1);
						$('.nav-line.inactive').attr('data-grow', parseInt($('.nav-line.inactive').attr('data-grow'))+1);
					}
				}
				else {
					var id = this.element.id;
					if (parseInt($('.nav-line.inactive').attr('data-grow')) >= 0) {
						$('.nav-line.active').attr('data-grow', parseInt($('.nav-line.active').attr('data-grow'))+1);
						$('.nav-line.inactive').attr('data-grow', parseInt($('.nav-line.inactive').attr('data-grow'))-1);
					}
				}
				$('.nav-el[data-index="'+id+'"]').addClass('active');
			},
			offset: 100
		});
	});

	$('#cta').parent().each(function() {
		new Waypoint({
			element: this,
			handler: function(direction) {
				if (direction == 'up') {
					$('#cta').removeClass('fixed');
				} else {
					$('#cta').addClass('fixed');
				}
			},
			offset: -50
		});
	});

	$('#kontakt').each(function() {
		new Waypoint({
			element: this,
			handler: function(direction) {
				if (direction == 'up') {
					$('#cta').addClass('fixed');
				} else {
					$('#cta').removeClass('fixed');
				}
			},
			offset: 50
		});
	});

	$('.grid-info--right').each(function() {
		new Waypoint({
			element: this,
			handler: function(direction) {
				if (direction == 'up') {
					if ($('.grid-info--right').length > 1) $('.grid-info--right').eq(0).removeClass('grid-info--blend');
					$('.grid-info--right .logo').removeClass('fixed');
				} else {
					if ($('.grid-info--right').length > 1) $('.grid-info--right').eq(0).addClass('grid-info--blend');
					$('.grid-info--right .logo').addClass('fixed');
				}
			},
			offset: function() {
				return -1 * $('.fullpage').eq(0).outerHeight(true) - 50;
			}
		});
	});

	$('.grid-info--left').each(function() {
		new Waypoint({
			element: this,
			handler: function(direction) {
				if ($('.grid-info--left .logo.static').length) return;
				if (direction == 'up') {
					if ($('.grid-info--left').length > 1) $('.grid-info--left').eq(0).removeClass('grid-info--blend');
					$('.grid-info--left .logo').removeClass('fixed');
				} else {
					if ($('.grid-info--left').length > 1) $('.grid-info--left').eq(0).addClass('grid-info--blend');
					$('.grid-info--left .logo').addClass('fixed');
				}
			},
			offset: function() {
				return -1 * $('.fullpage').eq(0).outerHeight(true) - 50;
			}
		});
	});

	if ($('.fullpage').length > 1) $('.fullpage').eq(1).each(function() {
		new Waypoint({
			element: this,
			handler: function(direction) {
				if (direction == 'up') {
					$('.nav').eq(1).removeClass('nav--blend');
					$('.nav').removeClass('fixed');
				} else {
					$('.nav').eq(1).addClass('nav--blend');
					$('.nav').addClass('fixed');
				}
			},
			offset: -30
		});
	});

	if ($('.fullpage').eq(0).hasClass('color')) {
		$('.nav').addClass('color');
	} else if ($('.fullpage').eq(0).hasClass('light')) {
		$('.nav').addClass('light');
	} else if ($('.fullpage').eq(0).hasClass('dark')) {
		$('.nav').addClass('dark');
	}

	//Graphic Slideshow Animation
	var graphicInterval = null;
	var $graphic = $('.module-graphic-wrapper');

	$graphic.each(function() {
		new Waypoint({
			element: this,
			handler: function(direction) {
				if (!$graphic.find('.module-graphic-element.active').length) initGraphic($graphic);
			},
			offset: function() {
				return $(window).height() + 50;
			}
		});
	});
	$graphic.each(function() {
		new Waypoint({
			element: this,
			handler: function(direction) {
				if (!$graphic.find('.module-graphic-element.active').length) initGraphic($graphic);
			},
			offset: function() {
				return -1 * $graphic.outerHeight(true) - 50;
			} 
		});
	});

	function initGraphic(graphic) {
		var $graphic = $(graphic);
		$graphic.find('.module-graphic-element').eq(0).addClass('active');
		graphicInterval = setInterval(function() {
			var $currentElement = $graphic.find('.module-graphic-element.active');
			$currentElement.removeClass('active').next().addClass('active');
			$graphic.addClass('animate');
			var $clonedElement = $currentElement.clone().addClass('clone').appendTo($graphic);
			setTimeout(function() {
				$currentElement.remove();
				$graphic.removeClass('animate');
				$clonedElement.removeClass('clone');
			},750);
		}, 5000); 
	}

	var parallaxElement = $('.fullpage').eq(0).find('.fullpage-background');

	function heroParallax() {
		parallaxElement.css({
			transform: 'translateY('+(window.scrollY / 2)+'px)'
		});
        window.requestAnimationFrame(heroParallax);
	}

	heroParallax();

	$('.module-doubleslider').each(function(index, el) {

		var $wrapper = $(this);
		var speed = 750;
		var count = $wrapper.find('.module-doubleslider-textwrapper').children().length;

		if (count < 2) $wrapper.find('.module-doubleslider-arrow').hide();

	    $wrapper.find('.module-doubleslider-textwrapper').slick({
			infinite: true,
			slidesToShow: 1,
			slidesToScroll: 1,
			autoplay: $wrapper.find('.module-doubleslider-logowrapper').length ? false : true,
			arrows: $wrapper.find('.module-doubleslider-logowrapper').length ? false : true,
			prevArrow: $wrapper.find('.module-doubleslider-arrow.left'),
			nextArrow: $wrapper.find('.module-doubleslider-arrow.right'),
			draggable: true,
			speed: speed
	    }).on('beforeChange', function(event, slick, currentSlide, nextSlide) {
	    	if (nextSlide === currentSlide + 1 || (currentSlide === slick.slideCount - 1 && nextSlide === 0))
				$wrapper.find('.module-doubleslider-logowrapper').slick('slickNext');
			else
				$wrapper.find('.module-doubleslider-logowrapper').slick('slickGoTo', nextSlide);
		}).on('mouseenter touchstart focus', function(event) {
			$wrapper.find('.module-doubleslider-logowrapper').slick('slickPause');
		}).on('mouseleave', function(event) {
			$wrapper.find('.module-doubleslider-logowrapper').slick('slickPlay');
		});

		var slidesToShow = $wrapper.find('.module-doubleslider-logowrapper').attr('data-slidesToShow');

	    $wrapper.find('.module-doubleslider-logowrapper').on('init reInit beforeChange', function() {
			setTimeout(function() {
				$wrapper.find('.module-doubleslider-arrow').css('top', $wrapper.find('.module-doubleslider-logowrapper').height() / 2);
			}, 50);
		}).slick({
			infinite: true,
			adaptiveHeight: false,
			slidesToScroll: 1,
			slidesToShow: slidesToShow,
			pauseOnHover: true,
			pauseOnFocus: true,
			autoplay: true,
			autoplaySpeed: 5000,
			draggable: false,
			speed: speed,
			prevArrow: $wrapper.find('.module-doubleslider-arrow.left'),
			nextArrow: $wrapper.find('.module-doubleslider-arrow.right'),
			responsive: [{
				breakpoint: 640,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1
				}
			}]
		}).on('beforeChange', function(event, slick, currentSlide, nextSlide) {
	    	if (nextSlide === currentSlide + 1 || (currentSlide === slick.slideCount - 1 && nextSlide === 0))
				$wrapper.find('.module-doubleslider-textwrapper').slick('slickNext');
			else
				$wrapper.find('.module-doubleslider-textwrapper').slick('slickGoTo', nextSlide);
		}).on('click', '[data-slick-index]', function(event) {
			event.preventDefault();
			$wrapper.find('.module-doubleslider-logowrapper').slick('slickGoTo', $(this).attr('data-slick-index'));
		});
	});

	//Home Slideshow Animation
	if (typeof homeSlidesData !== 'undefined') {
		var $homeSlides = $('.grid-background');
		var $homeSlidesHeader = $('.home-subline');
		var $logo = $('.home-wrapper .logo');
		var homeSlidesCount = $homeSlides.length;
		var homeSlidesCurrent = -1;
		var homeSlidesRandom = -1;
		var homeSlidesDataCount = homeSlidesData.length;
		var homeSlideInit = function() {
			while (homeSlidesRandom === homeSlidesCurrent) {
				homeSlidesRandom = Math.floor((Math.random() * homeSlidesCount));
			}
			homeSlidesCurrent = homeSlidesRandom;
			var $homeSlide = $homeSlides.eq(homeSlidesCurrent);
			var homeSlideData = homeSlidesData[homeSlidesDataCount - 1];
			var $currentHeader = $homeSlidesHeader.find('span > span.active');
			homeSlidesDataCount--;
			$homeSlides.removeClass('active');
			$logo.addClass('transparent');
			$homeSlide.addClass('active').children('div').css({'background-image': 'url('+homeSlideData.img+')'});
			$currentHeader.addClass('transition').removeClass('active');
			setTimeout(function() {
				$currentHeader.html('').removeClass('transition');
			},500);
			setTimeout(function() {
				$logo.removeClass('transparent');
			},3500);
			$currentHeader.siblings('span').html(homeSlideData.text).addClass('active');
			if (homeSlidesDataCount === 0) homeSlidesDataCount = homeSlidesData.length;
		}
		setTimeout(function() {
			homeSlideInit();
		},1000);
		setInterval(homeSlideInit, 7500);
	}

	$(document).on('click', 'figure.can-fullscreen', function() {
		$(this).toggleClass('is-fullscreen');
	});

});

import $ from "jquery";
import "waypoints/lib/noframework.waypoints.js";

export function init() {

	if ($('.creator-bonus').length) {

		$('body').on('change', '.creator-element input', function(e) {
			checkSum();
		});
		checkSum();

		startBonusBar();

		$('.creator-bonus .creator-element-info').on('click', function() {
			$('.creator-bonus-wrapper').eq(0).scrollTo(200);
		});

		$('.creator-bonus .creator-bonus-button').on('click', function() {
			$('.creator-submit-wrapper').eq(0).scrollTo(200);
		});

	} else {		

		startPackageSelector();

	}

	$('[type="checkbox"][required] + .creator-switch-slider').on('click', function(e) {
		e.preventDefault(); // prevent fixed required service to be set to false
		var $this = $(this);
		$this.addClass('shake')
		setTimeout(function() {
			$this.removeClass('shake')
		}, 1000);
	});

	if ($('[data-surveydata]').length) {
		$('body').on('click', '[data-surveydata]', function(e) {
			var $linkElement = $(this);
			saveSurveyData($linkElement.attr('data-surveydata'));
			if ($linkElement.filter('[data-surveytarget]').length) {
				setSurveyData($linkElement);
			}
		});
	}
}

function startPackageSelector() {
	var activeIndex = $('[data-activepackage="true"]').eq(0).index();
	$('[data-activepackage]').hover(function() {
		if ($(this).attr('data-activepackage') == 'false') {
			var currentIndex = $(this).index() + 1;
			$('[data-activepackage]').removeClass('hover');
			$('[data-activepackage]:nth-child('+currentIndex+')').addClass('hover');
		}
	}, function() {
		$('[data-activepackage]').removeClass('hover');
	}).on('click', function() {
		var currentIndex = $(this).index() + 1;
		$('[data-activepackage] input[type="radio"]').prop('checked', false);
		$('[data-activepackage]:nth-child('+currentIndex+') input[type="radio"]').prop('checked', true);
		$('[data-activepackage]').removeClass('hover');
		$('[data-activepackage]').attr('data-activepackage', 'false');
		$('[data-activepackage]:nth-child('+currentIndex+')').attr('data-activepackage', 'true');
	});
}

function setSurveyData($linkElement) {
	var params = localStorage.getItem('surveydata');
	$linkElement.attr('href', $linkElement.attr('href') + '?' + params);
	localStorage.removeItem('surveydata');
}

function saveSurveyData(newData) {
	var params = loadSurveyData();
	if (newData) {
		var newParamsKeyValues = newData.split('&');
		newParamsKeyValues.forEach(function(item, index) {
			var key   = item.split('=')[0];
			var value = item.split('=')[1];
			if (typeof params[key] !== 'undefined' && !isNaN(value)) {
				//if it's a number add it to the existing value
				params[key] = parseFloat(params[key]) + parseFloat(value);
			} else {
				params[key] = value;
			}
		});
	}

	var newParams = [];
	for (var key in params) {
		newParams.push(key+'='+params[key]);
	};

	localStorage.setItem('surveydata', newParams.join('&'));
}

function loadSurveyData() {
	var existingData = localStorage.getItem('surveydata');
	var existingParams = [];
	if (existingData) {
		var existingParamsKeyValues = existingData.split('&');
		existingParamsKeyValues.forEach(function(item, index) {
			var key   = item.split('=')[0];
			var value = item.split('=')[1];
			existingParams[key] = value;
		});
	}
	return existingParams;
}

function checkSum() {
	var currentSum = 0;
	$('.creator-element input').each(function(index, el) {
		var $this = $(this);
		var price = parseInt($this.attr('data-price'));
		if ($this.prop('type') == 'checkbox') {
			if ($this.is(':checked')) currentSum += price;
		} else {
			currentSum += parseInt($this.val()) * price;
		}
	});
	$('.creator-bonus').each(function(index, el) {
		var $this = $(this);
		var $prev = $this.prev('.creator-bonus');
		var $next = $this.next('.creator-bonus');
		var $progress = $this.find('.creator-progress');
		var limit = parseInt($progress.attr('data-limit').replace(/\./g, ''));
		var width = 100 * currentSum / limit;
		var formattedCurrentSum = currentSum.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
		$progress.css('--progress', width+'%').attr('data-current', formattedCurrentSum);
		if (width >= 100) {
			$this.addClass('active');
			if ($next.length) $this.removeClass('current');
			if ($next.length) $next.addClass('current');
		} else {
			$this.removeClass('active');
			$this.removeClass('current');
			if (!$prev.length || $prev.hasClass('active')) {
				$this.addClass('current');
			}
		}
		var offerPercent = 0;
		if ($('.creator-bonus.active').length) offerPercent = $('.creator-bonus.active').last().find('.creator-progress').attr('data-percent');
		console.log((1 + parseInt(offerPercent) / 100));
		var formattedTotalSum = (currentSum * (1 - parseInt(offerPercent) / 100)).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
		$('[data-sum]').text(formattedCurrentSum);
		$('[data-offersum]').text(formattedTotalSum);
		$('[data-sumpercent]').text(offerPercent);
	});	
}

function startBonusBar() {

	setBonusWrapperHeight();

	new Waypoint({
		element: $('.creator-bonus-wrapper')[0],
		handler: function(direction) {
			if (direction == 'up') {
				setBonusWrapperHeight();
				$('.creator-bonus').addClass('fixed');
			} else {
				$('.creator-bonus').removeClass('fixed');
			}
		},
		offset: function() {
			return $(window).height();
		}
	});

	$(window).on('resize', function() {
		$('.creator-bonus-wrapper').css('height','');
	});
}

function setBonusWrapperHeight() {

	if ($('.creator-bonus').eq(0).hasClass('fixed')) {
		return;
	}
	

	$('.creator-bonus-wrapper').height($('.creator-bonus.current').outerHeight(true));
}

function isCreatorHeigherThanWindow() {
	var fullHeight = $('.creator-wrapper').height() - $('.creator-bonus.current').outerHeight(true);
	return fullHeight > $(window).height();
}

import $ from "jquery";

var waitingTime = 20000;
var timer = true;
var $popup = $();

export function init(id, withTimer = true) {

	var $newPopup = $('#'+$.escapeSelector(id));

	if (!$newPopup.length) return;

	if (withTimer) {

		$popup = $newPopup;

		var lastPopupTime = parseInt(localStorage.getItem('popupOpen'));
		var currentPopupTime = Date.now();

		if (!lastPopupTime || (currentPopupTime > (lastPopupTime + 1000*60*60*168))) {

			setTimer();

			$(window).on('scroll click keydown mousemove', function(e) {
				clearTimeout(timer);
				if (timer) setTimer();
			});
		}

	} else if (!$newPopup.hasClass('popup--opened')) {
		var id = $newPopup.prop('id');
		$('body').on('click', '[popup="'+id+'"]', function(e) {
			e.preventDefault();
			$('body').css('overflow','hidden');
			$newPopup.removeClass('popup--closed').addClass('popup--opened');
		});
		$newPopup.on('click', '.popup-close', function(e) {
			e.preventDefault();
			$('body').css('overflow','');
			$newPopup.removeClass('popup--opened').addClass('popup--closed');
		});
		$newPopup.addClass('popup--opened');
	}
}

function setTimer() {

	timer = setTimeout(function() {
		if ($('.wmBox_overlay').is(':visible')) {
			clearTimeout(timer);
			if (timer) setTimer();
			return;
		}
		localStorage.setItem('popupOpen', Date.now());
		$popup.addClass('popup--opened');
		$popup.on('click', '.popup-close', function(e) {
			e.preventDefault();
			$popup.removeClass('popup--opened').addClass('popup--closed');
		});
		timer = false;
	}, waitingTime);
}

import $ from "jquery";

var fixedHeightElements = $();
var initialHeight = 0;
var initialWidth = 0;

export function init(elems) {
	fixedHeightElements = $(elems);
	init();
};

export function reset() {
	recalc();
};

function init() {
	initialHeight = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
	initialWidth = $(window).width();
	$(window).on('resize', function() {
	var newHeight = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
	if($(window).width() != initialWidth && newHeight != initialHeight) {
		recalc();
	}
	});
	setHeight();
}

function setHeight() {
	setTimeout(function() {
	fixedHeightElements.each(function() {
		$(this).css('height',$(this).attr('data-height'));
		$(this).height($(this).height());
	});
	}, 500);
}
	
function recalc() {
	initialHeight = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
	initialWidth = $(window).width();
	setHeight();
}
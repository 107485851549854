import $ from "jquery";

export function init() {

	$('body').prepend('<div class="wmBox_overlay"><div class="wmBox_centerWrap"><div class="wmBox_centerer"><div class="wmBox_closeBtn"><p>x</p></div><div class="wmBox_contentWrap"><div class="wmBox_scaleWrap">');

	$('body').on('click', '[data-popup]', function(e) {
		e.preventDefault();
		$('body').css('overflow','hidden');
		$('.wmBox_overlay').fadeIn(750);
		var mySrc = $(this).attr('data-popup');
		$('.wmBox_overlay .wmBox_scaleWrap').html('').append('<iframe src="'+mySrc+'" frameborder="0">');
	});

	$('body').on('click', '.wmBox_closeBtn', function(e) {
		e.preventDefault();
		$('body').css('overflow','');
		$('.wmBox_overlay').fadeOut(750, function() {
			$(this).find('iframe').remove();
		});
	});
}
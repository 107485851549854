import $ from "jquery";

export function load(container = 'body') {

    const $container = $(container);
    const $loader = $container.find('#loader, .loader');
    const _time = new Date().getTime();
    const _limit = 4;
    let _total = 0;
    let _progress = 0;

    if (!$loader.length) return;

    $loader.show();
    $('body').css('overflow', 'hidden');

    let $images = $container.find('img[src]:not([id*="Cookiebot"]), [style*="background-image"]:not([id*="Cookiebot"])');

    $images = $images.slice(0, _limit);

    const $iframes = $container.find('iframe[src*="cloudworx"]');

    _total = $images.length + $iframes.length;

    $iframes.each(function() {

        const $this = $(this);
        const src = $this.prop('src');
        let isloaded = false;

        $(window).on("message", function(e) {
            const data = e.originalEvent.data;
            if (typeof data !== "string") {
                return;
            }
            if ((data.indexOf('[iFrameSizer]iFrameResizer') !== -1 && data.indexOf(':init') !== -1)
                && src.replace(/^(.*?\/\/[^/]+).*$/,'$1') === e.originalEvent.origin.replace(/^(.*?\/\/[^/]+).*$/,'$1')
                && !isloaded) {
                _progress++;
                isloaded = true;
                checkProgress($loader, _time, _progress, _total);
            }
        });

        $this.one('load', function() {

            if (!isloaded) {
                _progress++;
                isloaded = true;
                checkProgress($loader, _time, _progress, _total);
            }
        });

    });

    $images.each(function() {

        let src;
        const $this = $(this);
        const $img = $(new Image());

        if ($this.prop('src')) 
            src = $this.prop('src');
        else
            src = $this.css('background-image').replace(/url\("?([^)"]+)"?\)/, '$1');

        $img.one('load', function() {

            _progress++;

            checkProgress($loader, _time, _progress, _total);

        }).prop('src', src)
        .each(function() { if (this.complete) $(this).trigger('load');});

    });

    //if it takes too long
    // setTimeout(function() {
    //     if ($loader.is(':visible')) showContainer($loader);
    // }, 5000);

    //if there were no images at all
    if (_total === 0) {
        showContainer($loader);
    }
}

function checkProgress($loader, _time, _progress, _total) {
    if (_progress === _total) {

        const delta = new Date().getTime() - _time;
        if (delta >= 1000)
            showContainer($loader);
        else
            setTimeout(function() {showContainer($loader)}, (1000 - delta));
    }
}

function showContainer($loader) {

    $loader.fadeOut(500, function() {
        if (!$('.popup--opened').length) $('body').css('overflow', 'auto');
    });
}
